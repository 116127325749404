import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function createData(role_id, type, service, instance, endpoint, method) {
  return { role_id, type, service, instance, endpoint, method };
}

export default function AllowedDenied({data, category}) {

    let role_id = '';
    let rows = [];

    for (let i = 0; i < data.length; i++) {
        if (category === 'featured') {
            role_id = data[i]['featured_role_id'];
        }
        if (category === 'domain') {
            role_id = data[i]['domain_role_id'];
        }

        for (let j = 0; j < data[i]['allowedDenied']['allowed'].length; j++) {
            rows.push(createData(role_id, 'Allow', data[i]['allowedDenied']['allowed'][j]['service'], data[i]['allowedDenied']['allowed'][j]['instance'], data[i]['allowedDenied']['allowed'][j]['endpoint'], data[i]['allowedDenied']['allowed'][j]['method']));
        }

        for (let j = 0; j < data[i]['allowedDenied']['denied'].length; j++) {
            rows.push(createData(role_id, 'Deny', data[i]['allowedDenied']['denied'][j]['service'], data[i]['allowedDenied']['denied'][j]['instance'], data[i]['allowedDenied']['denied'][j]['endpoint'], data[i]['allowedDenied']['denied'][j]['method']));
        }

    }

    return (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 800 }} size="small" aria-label="a dense table">
            <TableHead>
            <TableRow sx={{ }}>
                <TableCell align="center"><b>Role ID</b></TableCell>
                <TableCell align="center"><b>Type</b></TableCell>
                <TableCell><b>Service</b></TableCell>
                <TableCell><b>Instance</b></TableCell>
                <TableCell><b>Endpoint</b></TableCell>
                <TableCell><b>Method</b></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row) => (
                <TableRow
                key={row.role_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell component="th" scope="row" align="center">
                    {row.role_id}
                </TableCell>
                <TableCell align="center">{row.type}</TableCell>
                <TableCell>{row.service}</TableCell>
                <TableCell>{row.instance}</TableCell>
                <TableCell>{row.endpoint}</TableCell>
                <TableCell>{row.method}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    );
}
