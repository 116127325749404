import * as React from 'react';
import { useState, useEffect } from 'react';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { styled, alpha } from '@mui/material/styles';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import AccountRoles from './AccountRoles';

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.content}`]: {
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0.2, 0),
    },
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.groupTransition}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

function ExpandIcon(props) {
    return <AddBoxRoundedIcon {...props} sx={{ opacity: 0.8 }} />;
}

function CollapseIcon(props) {
    return <IndeterminateCheckBoxRoundedIcon {...props} sx={{ opacity: 0.8 }} />;
}

function EndIcon(props) {
    return <DisabledByDefaultRoundedIcon {...props} sx={{ opacity: 0.3 }} />;
}

export default function AccountsView({ rows, account_id }) {

    const jsonData = JSON.parse(rows);
    const [expandedItems, setExpandedItems] = React.useState([]);
    const [itemIDS, setItemIDs] = React.useState([]);

    const handleExpandedItemsChange = (event, itemIds) => {
        setExpandedItems(itemIds);
    };

    const handleExpandClick = () => {
        setExpandedItems((oldExpanded) =>
          oldExpanded.length === 0
            ? itemIDS
            : [],
        );
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));

    function convertEpoch(value) {
        if (!value) {
            return ''
        }
        var myDate = new Date(value);
        return myDate.toGMTString();
    }

    useEffect(() => {
        let itemID = [];
        for (let i = 0; i < jsonData.entries.length; i ++ ) {
            itemID.push('account_' + i);
            itemID.push('account_' + i + '_details');
            itemID.push('account_' + i + '_featured_roles');
            itemID.push('account_' + i + '_domain_roles');
            itemID.push('account_' + i + '_services');
        }
        setItemIDs(itemID);

    }, []);

    return (

        <>
        <Button onClick={handleExpandClick}>
          {expandedItems.length === 0 ? 'Expand all' : 'Collapse all'}
        </Button>

        <SimpleTreeView
            aria-label="customized"
            defaultExpandedItems={['1', '3']}
            slots={{
            expandIcon: ExpandIcon,
            collapseIcon: CollapseIcon,
            endIcon: EndIcon,
            }}
            sx={{ overflowX: 'hidden', minHeight: 270, flexGrow: 1, maxWidth: 1000 }}
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
        >

            {jsonData.entries.map((item,i) => 
                <CustomTreeItem itemId={'account_' + i} label={item.title + ' (' + item.id.split(/[/]+/).pop() + ')' + (item.disabled ? ' -- Disabled' : '') }>
                    <CustomTreeItem itemId={'account_' + i + '_details'} label={'Account Details'}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}><Item><b>ID</b></Item></Grid><Grid item xs={9}><Item><a href={item.id} target="_blank">{item.id}</a></Item></Grid>
                                <Grid item xs={3}><Item><b>Title</b></Item></Grid><Grid item xs={9}><Item>{item.title}</Item></Grid>
                                <Grid item xs={3}><Item><b>Description</b></Item></Grid><Grid item xs={9}><Item>{(item.description) ? item.description : 'No Record Found'}</Item></Grid>
                                <Grid item xs={3}><Item><b>Added</b></Item></Grid><Grid item xs={9}><Item>{convertEpoch(item.added)}</Item></Grid>
                                <Grid item xs={3}><Item><b>Owner ID</b></Item></Grid><Grid item xs={9}><Item>{item.ownerId}</Item></Grid>
                                <Grid item xs={3}><Item><b>Locked</b></Item></Grid><Grid item xs={9}><Item>{(item.locked) ? 'Yes' : 'No'}</Item></Grid>
                                <Grid item xs={3}><Item><b>Disabled</b></Item></Grid><Grid item xs={9}><Item>{(item.disabled) ? 'Yes' : 'No'}</Item></Grid>
                                <Grid item xs={3}><Item><b>Account Type</b></Item></Grid><Grid item xs={9}><Item>{item.accountType}</Item></Grid>
                                <Grid item xs={3}><Item><b>Usage Type</b></Item></Grid><Grid item xs={9}><Item>{item.usageType}</Item></Grid>
                                <Grid item xs={3}><Item><b>PID</b></Item></Grid><Grid item xs={9}><Item>{item.pid}</Item></Grid>
                                <Grid item xs={3}><Item><b>Region</b></Item></Grid><Grid item xs={9}><Item>{(item.region) ? item.region : 'No Record Found'}</Item></Grid>
                            </Grid>
                        </Box>
                    </CustomTreeItem>

                    <CustomTreeItem itemId={'account_' + i + '_featured_roles'} label={'Featured Roles'}>
                        <Box sx={{ flexGrow: 1 }}>
                            <AccountRoles data={item.assigned_roles} category='feature' /> 
                        </Box>
                    </CustomTreeItem>

                    <CustomTreeItem itemId={'account_' + i + '_domain_roles'} label={'Domain Roles'}>
                        <Box sx={{ flexGrow: 1 }}>
                            <AccountRoles data={item.assigned_roles} category='domain' />   
                        </Box>
                    </CustomTreeItem>

                    <CustomTreeItem itemId={'account_' + i + '_services'} label={'Services Registered'}>
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Item>
                                        <ul>
                                        {Object.keys(item.services).map((key, index)=>(
                                            (key !== 'No Records') ? <li key={index}>{<a href={item.services[key]} target='_blank'>{key}</a>}</li> : <li>None</li>
                                        ))}
                                        </ul>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </CustomTreeItem>

                </CustomTreeItem>
            )}

        </SimpleTreeView>
        </> 

    );

}