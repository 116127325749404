import * as React from 'react';
import '../App.css';
import Box from '@mui/material/Box';
import ButtonAppBar from '../providers/TopNavigation';
import Button from '@mui/material/Button';
import Sidenav from '../components/Sidenav';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { useIsAuthenticated } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider, useMsal } from '@azure/msal-react';
import { loginRequest } from "../utils/authConfig";


export default function Home() {

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    
    const logout = (event) => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }

    const signin = (event) => {
        instance.loginRedirect(loginRequest).catch(
            e => {console.log(e);
        });
    }

    return (
        <>
        <AuthenticatedTemplate>
        <Box sx={{ flexGrow: 1 }}>
            <ButtonAppBar />
            <table width="100%" cellSpacing="0" cellPadding="0">
                <tr>
                    <td width="5%"><Sidenav/></td>
                    <td valign='top'>
                        {(isAuthenticated) ? (<div align='right'><Typography variant="body1" sx={{ mr: 2, mt:0 }}>Hello <b>{ accounts[0].name }</b> <Button variant="text" color='error' onClick={logout}>Logout</Button></Typography></div>) : null }
                        <Box sx={{ flexGrow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={9}> 
                                <Card sx={{ minWidth: 275, ml:5, mt:2 }}>
                                    <CardContent>
                                    <Typography variant="h5" gutterBottom>
                                        Ops SRE
                                    </Typography>
                                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                        A Comcast Technology Solutions Team
                                        </Typography>
                                        <br />
                                        <Typography variant="body2">
                                            We look after the stability and resiliency of the operations across all services and customers round the globe and are Slackable at <a href='https://comcast.enterprise.slack.com/admin/user_groups'>@ops-sre</a><br />
                                            We work hand in hand with Product Engineering, Dedicated Support Engineers, Application Support Engineers, DBAs and other internal teams where necessary to 
                                            make the customer's end user experience as seemless as possible. 
                                            <br /><br />
                                            We are a team of 10 like-minded engineers spread across the US, UK and India. Reach us if you need to, we will help you achieve your goals from stability and resiliency standpoint.
                                        </Typography>
                                        <img src='/images/tag-cloud.png' height='480' border='0' alt='tag cloud' />
                                    </CardContent>
                                </Card>
                                </Grid>
                                <Grid item xs={3} align='center'>
                                    <br />
                                    <img src="/images/teamwork.png" height='200' alt='teanwork' />
                                    <Typography variant="h5" gutterBottom>
                                        Teamwork
                                        <br /><br />
                                        <div align='left' sx={{ ml:50 }} width='100px' padding='50px'>
                                            <SimpleTreeView sx={{ ml:5, mr:5 }}>
                                                <TreeItem itemId="team-us" label="United States">
                                                    <TreeItem itemId="us-member-1" label="1. Patryk Chmura" />
                                                    <TreeItem itemId="us-member-2" label="2. Michael Nargang" />
                                                    <TreeItem itemId="us-member-3" label="3. Caleb Leung" />
                                                    <TreeItem itemId="us-member-4" label="4. Eric Grant" />
                                                    <TreeItem itemId="us-member-5" label="5. Eric Mak" />
                                                </TreeItem>
                                                <TreeItem itemId="team-uk" label="United Kingdom">
                                                    <TreeItem itemId="uk-member-1" label="6. John Williams" />
                                                    <TreeItem itemId="uk-member-2" label="7. Janak Patel" />
                                                    <TreeItem itemId="uk-member-3" label="8. Nick Hitch" />
                                                    <TreeItem itemId="uk-member-4" label="9. Pallav Pal" />
                                                </TreeItem>
                                                <TreeItem itemId="team-india" label="India">
                                                    <TreeItem itemId="india-member-1" label="10. Premnath Karunamurthy" />
                                                </TreeItem>
                                            </SimpleTreeView>
                                        </div>
                                    </Typography>                            
                                </Grid>
                                <Grid item xs={12} align='center' sx={{ mt:7 }} >
                                    <a href='https://github.com/comcast-global-operations-sre'><img src='/images/github.png' height='40' alt='github' /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href='https://comcastcsp.datadoghq.com/apm/home'><img src='/images/datadog.png' height='40' alt='datadog' /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href='https://ci-kilo.delivery.comcast.net/'><img src='/images/concourse.png' height='40' alt='concourse' /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href='https://tpx.sys.comcast.net/secure/Dashboard.jspa'><img src='/images/jira.png' height='40' alt='jira' /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href='https://awslogin.comcast.com/'><img src='/images/aws.png' height='40' alt='aws' /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href='https://rundeck.corp.theplatform.com:4443/user/login'><img src='/images/rundeck.png' height='40' alt='rundeck' /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href='https://splunk-prod.corp.theplatform.com/en-US/app/search/search'><img src='/images/splunk.png' height='40' alt='splunk' /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href='http://tpzenoss.corp.theplatform.com/zport/dmd/Events/evconsole'><img src='/images/zenoss.jpg' height='40' alt='zenoss' /></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href='https://etwiki.sys.comcast.net/display/CVPO/CVP+Operations'><img src='/images/confluence.jpg' height='40' alt='confluence' /></a>
                                </Grid>
                            </Grid>
                        </Box>

                    </td>
                </tr>
            </table>
        </Box>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
            <div align='center'><Button variant="contained" onClick={signin} sx={{ mt:25 }}>Login with SSO</Button></div>
        </UnauthenticatedTemplate>
        </>
    );
}

