import * as React from "react";
import { useState } from 'react';
import { Grid, TextField, Button } from "@mui/material";
import { useText } from "../providers/TextProvider";
import validator from 'validator'
import '../bootstrap-5.3.3-dist/css/bootstrap.css'

export default function Input({ url, setUrl, retrieveData, buttonEnabled }) {
    const textValues = useText();
    const [btnEnabled, setBtnEnabled] = useState(false);
    const [helper, setHelper] = useState('');

    const validate = (value) => { 
        if (validator.isURL(value)) { 
            setBtnEnabled(true);
            setHelper('')
        } else { 
            setBtnEnabled(false);
            setHelper('Invalid URL')
        } 
    } 

    return (
        <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            xs={12}
            sm={6}
            lg={6}
            sx={{ my: 4 }}
        >
            <Grid item sm={6} md={6}>
                <TextField
                    fullWidth
                    required
                    id="url-input"
                    label="Required"
                    value={url}
                    onChange={(event) => {
                        setUrl(event.target.value);
                        validate(event.target.value);
                    }}
                    variant="standard"
                    helperText={helper}
                ></TextField>

            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    disabled={!btnEnabled}
                    onClick={() => retrieveData()}
                    size="large"
                >
                    {textValues.submit_button} 
                </Button>
            </Grid>
        </Grid>
    );
}