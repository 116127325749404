import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box, Container, CircularProgress } from '@mui/material';
import isEmpty from "lodash/isEmpty";
import Alert from '@mui/material/Alert';
import { ErrorBoundary } from 'react-error-boundary';
import { getMPXUser, getAccountIDs, getAccountNames, getRoleNames, getRoleIDs, getAllowDeny } from '../utils/dataService';
import UserView from './DisplayUserRoles';
import { isNull } from 'lodash';


export default function LookupTool() {

  const [helper, setHelper] = useState('');
  const [textToSearch, setTextToSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [noRecords, setNoRecords] = useState(false);

  const retrieveData = async () => {

    setLoading(true);
    const mpx_id = await getMPXUser(textToSearch);   

    if (!mpx_id) {
      setLoading(false);
      setNoRecords(true);
      setData();
    }
    else {

      const userDetails = await getAccountIDs(mpx_id);
      const account_names = await getAccountNames(userDetails[0]);
      const role_names = await getRoleNames(userDetails[2]);
      const account_roles = await getRoleIDs(userDetails[0]);
      const role_allow_deny = await getAllowDeny(account_roles[1]);

      let accounts = [];

      for (let i = 0; i < userDetails[0].length; i++) {
        
        let role_title = '';

        for (let x = 0; x < role_names.length; x++) {
          if (role_names[x]['role_id'] === userDetails[2][i]) {
            role_title = role_names[x]['role_title'];
          }
        }

        let account_featured_roles = account_roles[0][i]['featured_roles'];
        let account_domain_roles = account_roles[0][i]['domain_roles'];

        let acc_feature_roles = [];
        let acc_domain_roles = [];

        for (let x = 0; x < account_featured_roles.length; x++) {
          let allowDenyData = role_allow_deny.find(({role_id}) => role_id === account_featured_roles[x]);
          let allowDeny = {'allowed': allowDenyData['allowed'], 'denied': allowDenyData['denied']};
          let featured_roles = {'featured_role_id': allowDenyData['role_id'], 'allowedDenied': allowDeny};
          acc_feature_roles.push(featured_roles);
        }

        for (let x = 0; x < account_domain_roles.length; x++) {
          let allowDenyData = role_allow_deny.find(({role_id}) => role_id === account_domain_roles[x]);
          let allowDeny = {'allowed': allowDenyData['allowed'], 'denied': allowDenyData['denied']};
          let domain_roles = {'domain_role_id': allowDenyData['role_id'], 'allowedDenied': allowDeny};
          acc_domain_roles.push(domain_roles);
        }

        let assigned_roles = {'featured_roles': acc_feature_roles, 'domain_roles': acc_domain_roles};
        accounts.push({'account_id': userDetails[0][i], 'account_name': account_names[i], 'permission_id': userDetails[1][i], 'role_id': userDetails[2][i], 'role_title': role_title, 'assigned_roles': assigned_roles }); 
      
      }

      setData(accounts);
      setLoading(false);
      setNoRecords(false);

    }

  }

  useEffect(() => {
    console.log('No Records: ', noRecords);
  }, [noRecords])


  return (
    
    <Box sx={{ flexGrow: 1 }}>
    <Container sx={{ mt: 5 }}>
    <Grid
      container
      item
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      xs={12}
      sm={6}
      lg={6}
      sx={{ my: 4 }}
    >
      <Grid item sm={6} md={6}>
          <TextField
              fullWidth
              required
              id="context"
              label="Search UserID / UserName"
              value={textToSearch}
              onChange={(event) => {
                setTextToSearch(event.target.value);
                setNoRecords(false);
              }}
              variant="standard"
              helperText={helper}
          ></TextField>

      </Grid>
      <Grid item>
          <Button
              variant="contained"
              onClick={() => retrieveData()}
              size="large"
          >
              Search 
          </Button>

      </Grid>
      </Grid>
      <Grid item sx={{ mt: 10}} align='left'>
        {!loading ? (
          !isEmpty(data) ? (
            <ErrorBoundary 
              fallback={
                  <Alert variant="filled" severity="error" sx={{ ml:45, mr:45 }}>
                      Unable to fetch data from data service.
                  </Alert>
              }
              onError={() => console.log("Error occured !!")}
            >
              <UserView rows={data} />

            </ErrorBoundary>
          ) :  noRecords ? <Alert variant="filled" severity="error" sx={{ ml:45, mr:45 }}>No records found for {textToSearch}</Alert> : null
                                    
        ) : (
            <CircularProgress
                size={24}
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                }}
            />
        )}
      </Grid>
    </Container>
    </Box>
  );

}
