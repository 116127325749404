// Functions for retrieving data from data service

const token = sessionStorage.getItem('token');

export const getMPXUser = async (textToSearch) => {

    let payload = '';
    let dataStream = '';
    let result;
    
    const userUrl = 'https://identity.auth.theplatform.com/idm/data/User/mpx?range=1-1&form=json&schema=1.5.0&pretty=true&token=' + token + '&';

    try {

        let url = userUrl + 'byUserName=' + textToSearch;
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Unable to fetch data by User Name');
        }
        
        const reader = response.body.getReader();

        while (!(result = await reader.read()).done) {
            const chunks = new TextDecoder().decode(result.value);
            dataStream += chunks;
        }

        payload = JSON.parse(dataStream);

        if (payload.entries.length === 0) {

            url = userUrl + 'byEmail=' + textToSearch;
            const response = await fetch(url);

            if (!response.ok) {
                throw new Error('Unable to fetch data by Email');
            }

            const reader = response.body.getReader();
            dataStream = '';

            while (!(result = await reader.read()).done) {
                const chunks = new TextDecoder().decode(result.value);
                dataStream += chunks;
            }

            payload = JSON.parse(dataStream);

        }

        if (payload.entries.length > 0) {
            return payload.entries[0]['id'];
        }
        else {
            return null;
        }

    } catch (error) {
        console.log(error.message);
    }

}

export const getAccountIDs = async (mpxid) => {

    let payload = '';
    let dataStream = '';
    let result;
    let account_ids = [];
    let permission_ids = [];
    let role_ids = [];

    try {

        let url = 'https://access.auth.theplatform.com/data/Permission?byUserId=' + mpxid + '&range=1-100&form=cjson&schema=1.3.0&pretty=true&token=' + token;

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Unable to fetch permissions');
        }
        
        const reader = response.body.getReader();

        while (!(result = await reader.read()).done) {
            const chunks = new TextDecoder().decode(result.value);
            dataStream += chunks;
        }

        payload = JSON.parse(dataStream);

        for (let i = 0; i < payload.entries.length; i++) {

            let acc_id = ''

            if (payload.entries[i]['ownerId'] === 'urn:theplatform:auth:any') {
                acc_id = '227';
            }
            else {
                acc_id = payload.entries[i]['ownerId'].split(/[/]+/).pop();
            }
            let perm_id = payload.entries[i]['id'].split(/[/]+/).pop();
            let role_id = payload.entries[i]['roleIds'][0].split(/[/]+/).pop();

            account_ids.push(acc_id);
            permission_ids.push(perm_id);
            role_ids.push(role_id);

        }

        return [account_ids, permission_ids, role_ids];

    } catch (error) {
        console.log(error.message);
    }

}

export const getAccountNames = async (account_ids) => {

    let payload = '';
    let dataStream = '';
    let result;
    let account_id = '';
    let account_names = [];

    for (let i = 0; i < account_ids.length; i++) {
        account_id = account_id + account_ids[i] + ',';
    }
    account_id = account_id.replace(/,\s*$/, "");

    try {

        let url = 'https://access.auth.theplatform.com/data/Account/' + account_id + '?&range=1-100&form=cjson&schema=1.3.0&pretty=true&token=' + token;

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Unable to fetch account names');
        }
        
        const reader = response.body.getReader();

        while (!(result = await reader.read()).done) {
            const chunks = new TextDecoder().decode(result.value);
            dataStream += chunks;
        }

        payload = JSON.parse(dataStream);

        if (payload.entries) {
            for (let i = 0; i < payload.entries.length; i++) {
                account_names.push(payload.entries[i]['title']);
            }
        }
        else {
            account_names.push(payload['title']);
        }

        return account_names;

    } catch (error) {
        console.log(error.message);
    }

}

export const getRoleNames = async (role_ids) => {

    let payload = '';
    let dataStream = '';
    let result;
    let role_id = '';
    let role_titles = [];

    for (let i = 0; i < role_ids.length; i++) {
        role_id = role_id + role_ids[i] + ',';
    }

    role_id = role_id.replace(/,\s*$/, "");

    try {

        let url = 'https://access.auth.theplatform.com/data/Role/' + role_id + '?&range=1-100&form=cjson&schema=1.3.0&pretty=true&token=' + token;

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Unable to fetch role titles');
        }
        
        const reader = response.body.getReader();

        while (!(result = await reader.read()).done) {
            const chunks = new TextDecoder().decode(result.value);
            dataStream += chunks;
        }

        payload = JSON.parse(dataStream);

        if (payload.entries) {
            for (let i = 0; i < payload.entries.length; i++) {
                let role_id = payload.entries[i]['id'].split(/[/]+/).pop();;
                let role_title = payload.entries[i]['title'];
                role_titles.push({ role_id, role_title })
            }
        } 
        else {
            let role_id = payload['id'].split(/[/]+/).pop();;
            let role_title = payload['title'];
            role_titles.push({ role_id, role_title })
        }

        return role_titles;

    } catch (error) {
        console.log(error.message);
    }
}

export const getRoleIDs = async (account_ids) => {

    let payload = '';
    let dataStream = '';
    let result;
    let account_id = '';
    let account_role_mappings = [];
    let all_role_ids = [];

    for (let i = 0; i < account_ids.length; i++) {
        account_id = account_id + account_ids[i] + ',';
    }
    
    account_id = account_id.replace(/,\s*$/, "");

    try {

        let url = 'https://access.auth.theplatform.com/data/Account/' + account_id + '?range=1-100&form=cjson&schema=1.3.0&pretty=true&token=' + token;

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Unable to fetch role IDs');
        }
        
        const reader = response.body.getReader();

        while (!(result = await reader.read()).done) {
            const chunks = new TextDecoder().decode(result.value);
            dataStream += chunks;
        }

        payload = JSON.parse(dataStream);

        if (payload.entries) {

            for (let i = 0; i < payload.entries.length; i++) {

                let account_id = payload.entries[i]['id'].split(/[/]+/).pop();
                let featured_roles = [];
                let domain_roles = [];

                for (let x = 0; x < payload.entries[i]['featureRoleIds'].length; x++) {
                    let featured_role_id = payload.entries[i]['featureRoleIds'][x].split(/[/]+/).pop();
                    featured_roles.push(featured_role_id);
                    all_role_ids.push(featured_role_id);
                }

                for (let y = 0; y < payload.entries[i]['domainRoleIds'].length; y++) {
                    let domain_role_id = payload.entries[i]['domainRoleIds'][y].split(/[/]+/).pop();
                    domain_roles.push(domain_role_id);
                    all_role_ids.push(domain_role_id);
                }

                account_role_mappings.push({'account_id': account_id, 'featured_roles': featured_roles, 'domain_roles': domain_roles});

            }
        }

        else {

            let account_id = payload['id'].split(/[/]+/).pop();
            let featured_roles = [];
            let domain_roles = [];

            for (let x = 0; x < payload['featureRoleIds'].length; x++) {
                let featured_role_id = payload['featureRoleIds'][x].split(/[/]+/).pop();
                featured_roles.push(featured_role_id);
                all_role_ids.push(featured_role_id);
            }

            for (let y = 0; y < payload['domainRoleIds'].length; y++) {
                let domain_role_id = payload['domainRoleIds'][y].split(/[/]+/).pop();
                domain_roles.push(domain_role_id);
                all_role_ids.push(domain_role_id);
            }

            account_role_mappings.push({'account_id': account_id, 'featured_roles': featured_roles, 'domain_roles': domain_roles});

        }

        return [ account_role_mappings, all_role_ids ];

    } catch (error) {
        console.log(error.message);
    }

}

export const getAllowDeny = async (role_ids) => {

    let payload = '';
    let dataStream = '';
    let result;
    let role_id = '';
    let role_allow_deny = [];

    for (let i = 0; i < role_ids.length; i++) {
        role_id = role_id + role_ids[i] + ',';
    }
    role_id = role_id.replace(/,\s*$/, "");

    try {

        let url = 'https://access.auth.theplatform.com/data/Role/' + role_id + '?range=1-100&form=cjson&schema=1.3.0&pretty=true&token=' + token;

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Unable to fetch role based allow/deny');
        }
        
        const reader = response.body.getReader();

        while (!(result = await reader.read()).done) {
            const chunks = new TextDecoder().decode(result.value);
            dataStream += chunks;
        }

        payload = JSON.parse(dataStream);

        for (let i = 0; i < payload.entries.length; i++) {
            let role_id = payload.entries[i]['id'].split(/[/]+/).pop();
            let allowed = payload.entries[i]['allowOperations'];
            let denied = payload.entries[i]['denyOperations'];
            role_allow_deny.push({'role_id': role_id, 'allowed': allowed, 'denied': denied});
        }

        return role_allow_deny;

    } catch (error) {
        console.log(error.message);
    }

}

export const getAccountsData = async () => {

    let accounts = [];
    let all_accounts = '';
    let entryCount = 1;
    let startId = 0;

    localStorage.removeItem('all_accounts');
    let stringifiedData = localStorage.getItem('all_accounts');

    if (!stringifiedData) {

        try {

            console.log('Data not in store, fresh fetch ...');

            while (entryCount > 0) {

                let dataStream = '';
                let result;
                
                let url = 'https://access.auth.theplatform.com/data/Account?fields=id,title,ownerId,disabled&byId=' + startId + '~&sort=id&range=1-500&form=cjson&schema=1.3.0&pretty=true&token=' + token;

                const response = await fetch(url);

                if (!response.ok) {
                    throw new Error('Unable to fetch account details');
                }

                const reader = response.body.getReader();

                while (!(result = await reader.read()).done) {
                    const chunks = new TextDecoder().decode(result.value);
                    dataStream += chunks;
                }

                let payload = JSON.parse(dataStream);

                for (let x = 0; x < payload.entries.length; x++) {
                    let account_id = payload.entries[x]['id'].split(/[/]+/).pop();
                    let account_title = payload.entries[x]['title'];
                    let disabled = payload.entries[x]['disabled'];
                    let owner_id = payload.entries[x]['ownerId'].split(/[/]+/).pop();
                    accounts.push({account_id, account_title, owner_id, disabled});
                    startId = parseInt(account_id) + 1;
                }

                entryCount = payload.entryCount;

            }

            localStorage.setItem('all_accounts', JSON.stringify(accounts));
            console.log('Data stored in store ...');

        } catch (error) {
            console.log(error.message);
        }

    } 
    else {
        console.log('Data fetched from store ...');
    }

    stringifiedData = localStorage.getItem('all_accounts');
    all_accounts = JSON.parse(stringifiedData);

    return all_accounts;

}

export const filterAccounts = (category, status) => {

    let accounts = [];
    let parent_ids = [];

    const stringifiedData = localStorage.getItem('all_accounts');

    if (stringifiedData) {
        
        const all_accounts = JSON.parse(stringifiedData);

        for (let i = 0; i < all_accounts.length; i ++) {
            parent_ids.push(all_accounts[i]['owner_id']);
        }

        const uniqueParent_ids = Array.from(new Set(parent_ids));
        
        // active parent accounts only 
        if ((category) &&(status)) {
            for (let i = 0; i < all_accounts.length; i ++) {
                if ((!all_accounts[i]['disabled']) && (uniqueParent_ids.includes(all_accounts[i]['account_id']))) {
                    accounts.push({ 'account_id': all_accounts[i]['account_id'], 'account_name':  all_accounts[i]['account_title'] + ' (' + all_accounts[i]['account_id'] + ')' });
                }
            }
        }

        // parent accounts both active and inactive
        if ((category) &&(!status)) {
            for (let i = 0; i < all_accounts.length; i ++) {
                if (uniqueParent_ids.includes(all_accounts[i]['account_id'])) {
                    accounts.push({ 'account_id': all_accounts[i]['account_id'], 'account_name':  all_accounts[i]['account_title'] + ' (' + all_accounts[i]['account_id'] + ')' });
                }
            }
        }

        // both parent and child accounts but active
        if ((!category) &&(status)) {
            for (let i = 0; i < all_accounts.length; i ++) {
                if (!all_accounts[i]['disabled']) {
                    if (uniqueParent_ids.includes(all_accounts[i]['account_id'])) {
                        accounts.push({ 'account_id': all_accounts[i]['account_id'], 'account_name':  all_accounts[i]['account_title'] + ' (' + all_accounts[i]['account_id'] + ')' });
                    }
                    else {
                        accounts.push({ 'account_id': all_accounts[i]['account_id'], 'account_name':  ' -- ' + all_accounts[i]['account_title'] + ' (' + all_accounts[i]['account_id'] + ')' });
                    }
                }
            }
        }

        // both parent and child accounts active or inactive
        if ((!category) &&(!status)) {
            for (let i = 0; i < all_accounts.length; i ++) {
                if (uniqueParent_ids.includes(all_accounts[i]['account_id'])) {
                    accounts.push({ 'account_id': all_accounts[i]['account_id'], 'account_name':  all_accounts[i]['account_title'] + ' (' + all_accounts[i]['account_id'] + ')' });
                }
                else {
                    accounts.push({ 'account_id': all_accounts[i]['account_id'], 'account_name':  ' -- ' + all_accounts[i]['account_title'] + ' (' + all_accounts[i]['account_id'] + ')' });
                }
            }
        }
    }

    accounts.sort((a, b) => a.account_name > b.account_name);
    return accounts;

}

export const getAccountDetails = async (account_id) => {

    let payload = '';
    let dataStream = '';
    let result;
    let associatedRoles = [];

    try {

        let url = 'https://access.auth.theplatform.com/data/Account?account=' + account_id + '&range=1-100&form=cjson&schema=1.3.0&pretty=true&token=' + token;

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Unable to fetch account details');
        }
        
        const reader = response.body.getReader();

        while (!(result = await reader.read()).done) {
            const chunks = new TextDecoder().decode(result.value);
            dataStream += chunks;
        }

        payload = JSON.parse(dataStream);


        for (let i = 0; i < payload.entries.length; i ++) {

            let otherDetails = await accountSettings(payload.entries[i]['id'].split(/[/]+/).pop());
            let serviceDetails = await individualAccountDetails(payload.entries[i]['id'].split(/[/]+/).pop());
            payload.entries[i]['accountType'] = otherDetails[0];
            payload.entries[i]['usageType'] = otherDetails[1];
            payload.entries[i]['services'] = serviceDetails;
            payload.entries[i]['assigned_roles'] = [];

            for (let j = 0; j < payload.entries[i]['featureRoleIds'].length; j ++) {
                associatedRoles.push(payload.entries[i]['featureRoleIds'][j].split(/[/]+/).pop());
            }

            for (let j = 0; j < payload.entries[i]['domainRoleIds'].length; j ++) {
                associatedRoles.push(payload.entries[i]['domainRoleIds'][j].split(/[/]+/).pop());
            }

        }

        const rolesPayload = await accountRoles(associatedRoles);

        for (let x = 0; x < rolesPayload.entries.length; x ++) {
            for (let i = 0; i < payload.entries.length; i ++) {
                for (let j = 0; j < payload.entries[i]['featureRoleIds'].length; j ++) {
                    if (rolesPayload.entries[x]['id'] === payload.entries[i]['featureRoleIds'][j]) {
                        let role_details = { 'role_id': rolesPayload.entries[x]['id'].split(/[/]+/).pop(), 'role_title': rolesPayload.entries[x]['title'], 'role_type': rolesPayload.entries[x]['roleType'], 'allowed': rolesPayload.entries[x]['allowOperations'], 'denied': rolesPayload.entries[x]['denyOperations'] };
                        payload.entries[i]['assigned_roles'].push(role_details);
                    }
                }
                for (let j = 0; j < payload.entries[i]['domainRoleIds'].length; j ++) {
                    if (rolesPayload.entries[x]['id'] === payload.entries[i]['domainRoleIds'][j]) {
                        let role_details = { 'role_id': rolesPayload.entries[x]['id'].split(/[/]+/).pop(), 'role_title': rolesPayload.entries[x]['title'], 'role_type': rolesPayload.entries[x]['roleType'], 'allowed': rolesPayload.entries[x]['allowOperations'], 'denied': rolesPayload.entries[x]['denyOperations'] };  
                        payload.entries[i]['assigned_roles'].push(role_details);                 
                    }
                }
            }
        }

        return JSON.stringify(payload);

    } catch (error) {
        console.log(error.message);
    }

}

export const individualAccountDetails = async (account_id) => {

    let payload = '';
    let dataStream = '';
    let result;

    try {

        let url = 'https://access.auth.theplatform.com/data/Registry?account=' + account_id + '&range=1-100&form=cjson&schema=1.3.0&pretty=true&token=' + token;

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Unable to fetch account registry details');
        }
        
        const reader = response.body.getReader();

        while (!(result = await reader.read()).done) {
            const chunks = new TextDecoder().decode(result.value);
            dataStream += chunks;
        }

        payload = JSON.parse(dataStream);

        if (payload.entries[0]) {
            return payload.entries[0]['services'];
        }
        else {
            return {'No Records':'#'}
        }

    } catch (error) {
        console.log(error.message);
    }

}

export const accountSettings = async (account_id) => {

    let payload = '';
    let dataStream = '';
    let result;

    try {

        let url = 'https://access.auth.theplatform.com/data/AccountSettings?account=' + account_id + '&range=1-100&form=cjson&schema=1.3.0&pretty=true&token=' + token;

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Unable to fetch account registry details');
        }
        
        const reader = response.body.getReader();

        while (!(result = await reader.read()).done) {
            const chunks = new TextDecoder().decode(result.value);
            dataStream += chunks;
        }

        payload = JSON.parse(dataStream);

        if (payload.entries[0]) {
            return [payload.entries[0]['accountType'], payload.entries[0]['usageType']];
        }
        else {
            return ['', ''];
        }

    } catch (error) {
        console.log(error.message);
    }

}

export const accountRoles = async (roleIDs) => {

    let payload = '';
    let dataStream = '';
    let result;
    let role_ids = '';

    for (let i = 0; i < roleIDs.length; i++) {
        role_ids = role_ids + roleIDs[i] + ',';
    }
    role_ids = role_ids.replace(/,\s*$/, "");

    try {

        let url = 'https://access.auth.theplatform.com/data/Role/' + role_ids + '?fields=id,title,roleType,allowOperations,denyOperations&range=1-500&form=cjson&schema=1.3.0&pretty=true&token=' + token;

        const response = await fetch(url);

        if (!response.ok) {
            throw new Error('Unable to fetch account role details');
        }
        
        const reader = response.body.getReader();

        while (!(result = await reader.read()).done) {
            const chunks = new TextDecoder().decode(result.value);
            dataStream += chunks;
        }

        payload = JSON.parse(dataStream);

        return payload;

    } catch (error) {
        console.log(error.message);
    }

}
