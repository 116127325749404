import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


function createData(role_id, title, type, service, instance, endpoint, method) {
  return { role_id, title, type, service, instance, endpoint, method };
}

export default function AccountRoles({data, category}) {

    let rows = [];

    for (let i = 0; i < data.length; i++) {
        if (data[i]['role_type'] === category) {
            for (let j = 0; j < data[i]['allowed'].length; j++) {
                rows.push(createData(data[i]['role_id'], data[i]['role_title'], 'Allow', data[i]['allowed'][j]['service'], data[i]['allowed'][j]['instance'], data[i]['allowed'][j]['endpoint'], data[i]['allowed'][j]['method']));
            }
            for (let j = 0; j < data[i]['denied'].length; j++) {
                rows.push(createData(data[i]['role_id'], data[i]['role_title'], 'Deny', data[i]['denied'][j]['service'], data[i]['denied'][j]['instance'], data[i]['denied'][j]['endpoint'], data[i]['denied'][j]['method']));
            }
        }
    }

    return (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 800 }} size="small" aria-label="a dense table">
            <TableHead>
            <TableRow sx={{ }}>
                <TableCell align="center"><b>ID</b></TableCell>
                <TableCell><b>Role</b></TableCell>
                <TableCell align="center"><b>Type</b></TableCell>
                <TableCell><b>Service</b></TableCell>
                <TableCell><b>Instance</b></TableCell>
                <TableCell><b>Endpoint</b></TableCell>
                <TableCell><b>Method</b></TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row) => (
                <TableRow
                key={row.role_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                <TableCell component="th" scope="row" align="center">
                    {row.role_id}
                </TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell align="center">{row.type}</TableCell>
                <TableCell>{row.service}</TableCell>
                <TableCell>{row.instance}</TableCell>
                <TableCell>{row.endpoint}</TableCell>
                <TableCell>{row.method}</TableCell>
                </TableRow>
            ))}
            </TableBody>
        </Table>
        </TableContainer>
    );
}
