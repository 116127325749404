import * as React from 'react';
import '../App.css';
import Box from '@mui/material/Box';
import ButtonAppBar from '../providers/TopNavigation';
import Sidenav from '../components/Sidenav';
import { useIsAuthenticated } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider, useMsal } from '@azure/msal-react';
import { loginRequest } from "../utils/authConfig";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export default function Rmp() {

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    
    const logout = (event) => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }

    const signin = (event) => {
        instance.loginRedirect(loginRequest).catch(
            e => {console.log(e);
        });
    }

    return (
        <>
        <AuthenticatedTemplate>
        <Box sx={{ flexGrow: 1 }}>
            <ButtonAppBar />
            <table width="100%" cellspacing="0" cellpadding="0">
                <tr>
                    <td width="5%"><Sidenav/></td>
                    <td valign='top' align='center'>
                        {(isAuthenticated) ? (<div align='right'><Typography variant="body1" sx={{ mr: 2, mt:0 }}>Hello <b>{ accounts[0].name }</b> <Button variant="text" color='error' onClick={logout}>Logout</Button></Typography></div>) : null }
                        <iframe src="https://dt9oydv7jqqyr.cloudfront.net/RmpReferee/RMP%20Farm%20Referee%20Console.html" title="user lookup tool" frameborder="0" width="100%" height="890" align='center'></iframe>

                    </td>
                </tr>
            </table>
        </Box>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
            <div align='center'><Button variant="contained" onClick={signin} sx={{ mt:25 }}>Login with SSO</Button></div>
        </UnauthenticatedTemplate>
        </>
    );
}

