import isEmpty from "lodash/isEmpty";

export const hasKey = (inc_obj, inc_value) => {
    return inc_value in inc_obj ? true : false;
};

export const hasKeys = (inc_obj) => {
    return Object.keys(inc_obj).length > 0 ? true : false;
};

export const setNewSearchParams = (url, searchParams) => {
    if (!isEmpty(url)) {
        const setNewSearchParams = new URLSearchParams(searchParams.toString());
        setNewSearchParams.set("url", url);
        window.history.replaceState({}, "", `/?${setNewSearchParams.toString()}`);        
    }
};

export const getRows = async () => {

    const rows = [];

    try {
        const response = await fetch('https://ucmbiyh2qe.execute-api.us-east-2.amazonaws.com/default/ops-sre-dart-dev-requester');
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        const payload = JSON.parse(data.body).responses;

        payload.map((items, index) => ( 
            rows.push({ 
                resolves: items.resolves
            })
        ))     

        return rows;
        
    } catch (error) {
        console.log(error.message);
    }

};

export const getReportData = async () => {

    const rows = [];

    try {
        const response = await fetch('https://9k3s8jka9c.execute-api.us-east-2.amazonaws.com/dev/validate/'); 
        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }
        const data = await response.json();
        const payload = JSON.parse(data.body);

        payload.map((items, index) => ( 
            rows.push({ 
                record_type: items.record_type,
                domain_name: items.domain_name,
                aws_rdata: items.aws_rdata,
                gtm_rdata: items.gtm_rdata,
                status: items.status
            })
        ))

        return rows;
        
    } catch (error) {
        console.log(error.message);
    }

};

export const getMPXToken = async (usr, pwd) => {

    const headers = {
        Authorization: "Basic " + btoa(usr + ":" + pwd)
    };

    const SIGNIN = "https://identity.auth.theplatform.com/idm/web/Authentication/signIn?httpError=true&form=json&schema=1.0&cid={}";

    try {
        const response = await fetch(SIGNIN, { headers });

        if (!response.ok) {
            throw new Error('Please check your credentials');
        }

        const data = await response.json();
        let token =  data['signInResponse']['token'];
        sessionStorage.setItem('token', token);

        setTimeout(() => {
            sessionStorage.setItem('token', '');
        }, 3600000); // timeout after 1 hr

        return response;

    } catch (error) {
        console.log(error.message);
    }

};

export const signOutMPX = async (token) => {

    const SIGNOUT = 'https://identity.auth.theplatform.com/idm/web/Authentication/signOut?schema=1.1&form=json&cid={}&_token=' + token + '&httpError=true';

    try {
        const response = await fetch(SIGNOUT); 
        if (!response.ok) {
            throw new Error('Unable to signout properly');
        }

        const data = await response.json();
        sessionStorage.setItem('token', '');

    } catch (error) {
        console.log(error.message);
    }

};

export function applyFilter(data, dns, region, record, domain, matched) {
    
    let dataArray = [];

    if (region === 'All') {
        region = "(us-east-1|us-west-2|eu-west-1|eu-central-1)";
    }

    if (record === 'All') {
        record = "^(A|AAAA|CNAME|NS|TXT|SOA|MX|SRV)$";
    } else {
        record = "^" + record + "$";
    }

    if (domain === 'All') {
        domain = "(.eu|.us|.com).{0,1}$";
    } else {
        domain = domain + ".{0,1}$";
    }

    if (matched === 'All') {
        matched = "(true|false)";
    }

    data.map((items, index) => {
        
        if (dns === "*") {
            if (((items['Region']).match(region)) && ((items['Record Type']).match(record)) && ((items['Domain']).match(domain)) && ((items['Match']).toString().match(matched))) {
                dataArray.push(items);
            }  
        } else {
            let dnsPattern = new RegExp(dns);
            if (((items['Region']).match(region)) && ((items['Record Type']).match(record)) && ((items['Domain']).match(domain)) && ((items['Match']).toString().match(matched)) && (dnsPattern.test(items['Domain']))) {
                dataArray.push(items);
            } 
        }

    })

    return dataArray;
}

export function csvToArr(stringVal, splitter) {
    const [keys, ...rest] = stringVal
      .trim()
      .split("\n")
      .map((item) => item.split(splitter));
  
    const formedArr = rest.map((item) => {
      const object = {};
      keys.forEach((key, index) => (object[key] = item.at(index)));
      return object;
    });
    return formedArr;
}

export async function readAllChunks(readableStream) {
    const reader = readableStream.getReader();
    const chunks = [];
    let done, value;
    
    while (!done) {
        ({ value, done } = await reader.read());
        if (done) {
            return chunks;
        }
        const string = new TextDecoder().decode(value);
        chunks.push(string);
    }
}

export const getDNSReportDump = async () => {

    let dataStream = '';
    let result;
    let reportDump = [];

    const token = enVars('reporter', 'key');
    const api_url = enVars('reporter', 'url');

    try {
        const response = await fetch(
            api_url,
            {
            headers: {
                'x-api-key':`${token}`,
                'Content-Type': 'application/json',
            }
        });

        const reader = response.body.getReader();
        
        while (!(result = await reader.read()).done) {
            const chunks = new TextDecoder().decode(result.value);
            dataStream += chunks;
        }

        const JSONDump = JSON.parse(dataStream);

        JSONDump.map((item, index) => {
            
            let compare = '';
            let aws = '';
            let gtm = '';
            let global = '';

            if (item['match'] === 'True') {
                compare = true;
            }
            if (item['match'] === 'False') {
                compare = false;
            }

            aws = item['aws'].replace(/,/g, ';');
            gtm = item['gtm'].replace(/,/g, ';');
            global = item['recursive'].replace(/,/g, ';');

            let rowPayload = {
                "Domain": item['domain'],
                "Record Type": item['record_type'],
                "Region": item['region'],
                "AWS": aws,         // item['aws']
                "GTM": gtm,         // item['gtm']
                "Global": global,   // item['recursive']
                "Match": compare
            }
            reportDump.push(rowPayload);
        })

        return reportDump;

    } catch (error) {
        console.log(error.message);
    }
}

export function enVars(usage, type) {

    if (process.env.NODE_ENV === 'production') {
        if (type === 'url') {
            return (usage === 'requester') ? `${process.env.REACT_APP_DART_REQUESTER_API_URL_PROD}` : `${process.env.REACT_APP_DART_REPORT_API_URL_PROD}`;
        }
        if (type === 'key') {
            return (usage === 'requester') ? `${process.env.REACT_APP_DART_REQUESTER_API_KEY_PROD}` : `${process.env.REACT_APP_DART_REPORT_API_KEY_PROD}`;
        }
    }
    if (process.env.NODE_ENV === 'development') {
        if (type === 'url') {
            return (usage === 'requester') ? `${process.env.REACT_APP_DART_REQUESTER_API_URL_DEV}` : `${process.env.REACT_APP_DART_REPORT_API_URL_DEV}`;
        }
        if (type === 'key') {
            return (usage === 'requester') ? `${process.env.REACT_APP_DART_REQUESTER_API_KEY_DEV}` : `${process.env.REACT_APP_DART_REPORT_API_KEY_DEV}`;
        }
    }
}
