import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getMPXToken, signOutMPX } from '../utils/app_logic';
import { useMsal } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


export default function Login({referrer}) {

    const { accounts } = useMsal();
    const [mpxUser, setMpxUser] = useState();
    const [mpxPass, setMpxPass] = useState();
    const [showPassword, setShowPassword] = useState(false);
    const logged_in_user = 'mpx/' ; //+ accounts[0]['username'];
    const [mpxToken, setMpxToken] = useState();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const setUserID = (event) => {
        setMpxUser(event.target.value);
    };

    const setPassword = (event) => {
        setMpxPass(event.target.value);
    };

    const onClick = async () => {
        const response = await getMPXToken(mpxUser, mpxPass);
        if (response.status === 200) {
            const token = sessionStorage.getItem('token');
            setMpxToken(token);   
        }
    }

    useEffect(() => {
        setMpxUser(logged_in_user);
        console.log('Token set: ', mpxToken);
    }, []);

    return (

        <>
        <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={0}>
        <FormControl sx={{ m: 1, width: '40ch' }} variant="standard">
        <TextField
            id="input-with-icon-textfield"
            label="mpx/<username>@comcast.com"
            InputProps={{
            startAdornment: (
            <InputAdornment position="start">
            <AccountCircle />
            </InputAdornment>
            ),
            }}
            variant="standard"
            defaultValue={logged_in_user}
            onChange={setUserID}
        />
        { mpxToken ? 
        <Stack sx={{ width: '100%', mt:2 }} spacing={2}>
            <Alert
                severity="success"
                action={
                <Button color="inherit" size="small" onClick={() => {window.location.reload()}}>
                    Continue
                </Button>
            }
            >
            Token successfully set.
            </Alert>
        </Stack>

        : null }

        </FormControl>

        <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
        <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
        <Input
            id="standard-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
            <InputAdornment position="end">
                <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                >
                {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
            }
            onChange={setPassword}
        />
        </FormControl>

        <FormControl sx={{ m: 1, width: '15ch' }} variant="standard">
            <Button variant="contained" onClick={onClick}>MPX Login</Button>
        </FormControl>
            
        </Grid>
        </Box>
        </>
    )
}
