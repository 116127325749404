import * as React from 'react';
import { useState, useEffect } from 'react';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { styled, alpha } from '@mui/material/styles';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import AllowedDenied from './AllowedDenied';
import Button from '@mui/material/Button';


const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
    [`& .${treeItemClasses.content}`]: {
        padding: theme.spacing(0.5, 1),
        margin: theme.spacing(0.2, 0),
    },
    [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.groupTransition}`]: {
        marginLeft: 15,
        paddingLeft: 18,
        borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
    },
}));

function ExpandIcon(props) {
    return <AddBoxRoundedIcon {...props} sx={{ opacity: 0.8 }} />;
}

function CollapseIcon(props) {
    return <IndeterminateCheckBoxRoundedIcon {...props} sx={{ opacity: 0.8 }} />;
}

function EndIcon(props) {
    return <DisabledByDefaultRoundedIcon {...props} sx={{ opacity: 0.3 }} />;
}

export default function UserView({ rows }) {

    const [expandedItems, setExpandedItems] = React.useState([]);
    const [itemIDS, setItemIDs] = React.useState([]);

    const handleExpandedItemsChange = (event, itemIds) => {
        setExpandedItems(itemIds);
    };

    const handleExpandClick = () => {
        setExpandedItems((oldExpanded) =>
          oldExpanded.length === 0
            ? itemIDS
            : [],
        );
      };

    useEffect(() => {
        let itemID = [];
        for (let i = 0; i < rows.length; i ++ ) {
            itemID.push('account_' + i);
            itemID.push('account_' + i + '_permission');
            itemID.push('account_' + i + '_featured_roles');
            itemID.push('account_' + i + '_domain_roles');
        }
        setItemIDs(itemID);

    }, []);

    return (
        
        <>
        <Button onClick={handleExpandClick}>
          {expandedItems.length === 0 ? 'Expand all' : 'Collapse all'}
        </Button>

        <SimpleTreeView
            aria-label="customized"
            defaultExpandedItems={['1', '3']}
            slots={{
            expandIcon: ExpandIcon,
            collapseIcon: CollapseIcon,
            endIcon: EndIcon,
            }}
            sx={{ overflowX: 'hidden', minHeight: 270, flexGrow: 1, maxWidth: 1000 }}
            expandedItems={expandedItems}
            onExpandedItemsChange={handleExpandedItemsChange}
        >

            {rows.map((item,i) => 
                <CustomTreeItem itemId={'account_' + i} label={item.account_name + ' (' + item.account_id + ')'}>
                    <CustomTreeItem itemId={'account_' + i + '_permission'} label={'Permission ID - ' + item.permission_id + ' / Role ID - ' + item.role_id + ' (' +  item.role_title  + ')'}>
                        <CustomTreeItem itemId={'account_' + i + '_featured_roles'} label='Featured Roles'>
                            <AllowedDenied data={item.assigned_roles.featured_roles} category='featured' />
                        </CustomTreeItem>
                        <CustomTreeItem itemId={'account_' + i + '_domain_roles'} label='Domain Roles'>
                            <AllowedDenied data={item.assigned_roles.domain_roles} category='domain' />
                        </CustomTreeItem>
                    </CustomTreeItem>
                </CustomTreeItem>
            )}

        </SimpleTreeView>
        </> 

    );
}
