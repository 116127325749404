import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ButtonAppBar from '../providers/TopNavigation'
import '../App.css';
import Sidenav from '../components/Sidenav';
import Button from '@mui/material/Button';
import SearchTable from '../components/DisplaySearchTable';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { CSVLink } from "react-csv";
import TextField from '@mui/material/TextField';
import { applyFilter, getDNSReportDump } from '../utils/app_logic';
import { ErrorBoundary } from 'react-error-boundary';
import Alert from '@mui/material/Alert';
import { useIsAuthenticated } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider, useMsal } from '@azure/msal-react';
import { loginRequest } from "../utils/authConfig";
import Typography from '@mui/material/Typography';


export default function Report() {

    const [dns, setDns] = useState('');
    const [region, setRegion] = useState('');
    const [recordType, setRecordType] = useState('');
    const [domainType, setDomainType] = useState('');
    const [matched, setMatched] = useState('');
    const [searching, setSearching] = useState(false);
    const [data, setData] = useState([]);
    const [rows, setRows] = useState([]);
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();

    let filteredRows = [];

    const handleChangeRecordType = (event) => {
        setRecordType(event.target.value);
        let value = event.target.value;
        filteredRows = applyFilter(data, dns, region, value, domainType, matched);
        setRows(filteredRows);
    };

    const handleChangeDomainType = (event) => {
        setDomainType(event.target.value);
        let value = event.target.value;
        filteredRows = applyFilter(data, dns, region, recordType, value, matched);
        setRows(filteredRows);
    };

    const handleChangeRegion = (event) => {
        setRegion(event.target.value);
        let value = event.target.value;
        filteredRows = applyFilter(data, dns, value, recordType, domainType, matched);
        setRows(filteredRows);
    };

    const handleChangeDNS = (event) => {
        setDns(event.target.value);
        let value = event.target.value;
        filteredRows = applyFilter(data, value, region, recordType, domainType, matched);
        setRows(filteredRows);
    };

    const handleChangeMatched = (event) => {
        setMatched(event.target.value);
        let value = event.target.value;
        filteredRows = applyFilter(data, dns, region, recordType, domainType, value);
        setRows(filteredRows);
    };

    const retrieveData = async () => {
        const filter = `{ "dns": "${dns}", "region": "${region}", "record_type": "${recordType}", "domain_type": "${domainType}", "status": "${matched}" }`  
        console.log(filter);
        setSearching(true);
        filteredRows = applyFilter(data, dns, region, recordType, domainType, matched);
        setRows(filteredRows);
    }

    const logout = (event) => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }

    const signin = (event) => {
        instance.loginRedirect(loginRequest).catch(
            e => {console.log(e);
        });
    }

    const createXLS = (download) => {
        
        // Columns array created for table header
        const columns = [
            { Header: "ID", accessor: "id" },
            { Header: "Record", accessor: "record_type" },
            { Header: "Region", accessor: "region" },
            { Header: "Domain Name", accessor: "domain" },
            { Header: "AWS", accessor: "aws" },
            { Header: "GTM", accessor: "gtm" },
            { Header: "Global", accessor: "recursive" },
            { Header: "Matched", accessor: "match" },
        ];

        const csvData = [
            ["ID", "Record", "Region", "Domain Name", "AWS", "GTM", "Global", "Matched"],
            ...data.map(({ id, record_type, region, domain, aws, gtm, recursive, match }) => [
                id,
                record_type,
                region,
                domain,
                aws,
                gtm,
                recursive,
                match,
            ]),
        ];
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(0),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {
        setDns('*');
        setRegion('All');
        setRecordType('All');
        setDomainType('All');
        setMatched('All');

        const fetchData = async () => {
            const rows = await getDNSReportDump();
            setData(rows);
        }
        fetchData().catch(console.error);
        
    }, []);

    return ( 
        <>
        <AuthenticatedTemplate>       
        <Box sx={{ flexGrow: 1 }}>
            <ButtonAppBar />
            <table width="100%" cellSpacing="0" cellPadding="0">
                <tbody>
                    <tr>
                        <td width="5%" valign='top'><Sidenav/></td>
                        <td valign='top'>
                        {(isAuthenticated) ? (<div align='right'><Typography variant="body1" sx={{ mr: 2, mt:0 }}>Hello <b>{ accounts[0].name }</b> <Button variant="text" color='error' onClick={logout}>Logout</Button></Typography></div>) : null }
                        <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ mt:0 , ml:2, mb: 0, mr: 2 }}>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 150 }}>
                            <TextField
                                id="standard-helperText"
                                label="DNS name"
                                defaultValue="*"
                                helperText="Regex based search (* searches all)"
                                variant="standard"
                                onChange={handleChangeDNS}
                            />
                        </FormControl>

                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-standard-label">Region</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={region}
                                onChange={handleChangeRegion}
                                label="Regions"
                            >
                                <MenuItem value={"All"}>All</MenuItem>
                                <MenuItem value={"us-east-1"}>us-east-1</MenuItem>
                                <MenuItem value={"us-west-2"}>us-west-2</MenuItem>
                                <MenuItem value={"eu-west-1"}>eu-west-1</MenuItem>
                                <MenuItem value={"eu-central-1"}>eu-central-1</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-standard-label">Record Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={recordType}
                                onChange={handleChangeRecordType}
                                label="Record Type"
                            >
                                <MenuItem value={"All"}>All</MenuItem>
                                <MenuItem value={"A"}>A</MenuItem>
                                <MenuItem value={"AAAA"}>AAAA</MenuItem>
                                <MenuItem value={"CNAME"}>CNAME</MenuItem>
                                <MenuItem value={"NS"}>NS</MenuItem>
                                <MenuItem value={"TXT"}>TXT</MenuItem>
                                <MenuItem value={"SOA"}>SOA</MenuItem>
                                <MenuItem value={"MX"}>MX</MenuItem>
                                <MenuItem value={"SRV"}>SRV</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-standard-label">Domain Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={domainType}
                                onChange={handleChangeDomainType}
                                label="Domain Type"
                            >
                                <MenuItem value={"All"}>All</MenuItem>
                                <MenuItem value={".eu"}>.eu</MenuItem>
                                <MenuItem value={".us"}>.us</MenuItem>
                                <MenuItem value={".com"}>.com</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={matched}
                                onChange={handleChangeMatched}
                                label="Status"
                            >
                                <MenuItem value={"All"}>All</MenuItem>
                                <MenuItem value={"true"}>Matched</MenuItem>
                                <MenuItem value={"false"}>Not Matched</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl variant="standard" sx={{ mt: 2, ml:5, minWidth: 120 }}>
                            <Button variant="contained" onClick={() => retrieveData()}>Search</Button>
                        </FormControl>
                
                            {searching ? (     
                                <>   
                                <ErrorBoundary
                                fallback={
                                    <Alert variant="filled" severity="error" sx={{ mt:3, ml:45, mr:45 }} align="center">
                                        Unable to fetch results for the DNS provided, validate the RegEx string and <Button variant="outlinedtext" color="error" onClick={() => {window.location.reload()}}>retry</Button>
                                    </Alert>
                                }
                                onError={() => console.log("Error happened !!")}
                                >

                                {rows.length > 0 ? 
                                    (
                                        <Grid container spacing={0}>        
                                        <Grid xs={12} alignItems="stretch" >
                                            <br />
                                            <div align='center'><h4>DNS report generated as on {new Date().toLocaleString('en-GB', { timeZone: 'UTC' }) + ' UTC'} <Button variant="text"><CSVLink filename="report.csv" data={rows} target="_blank"><img src="/images/xl.png" height='25' alt='xl icon' onClick={() => createXLS()}/></CSVLink></Button></h4></div>
                                            
                                        </Grid>
                                        <Grid item xs={12}><Item>
                                        <SearchTable rows={rows} search={dns}/>
                                        </Item></Grid>
                                        </Grid>
                                    )     
                                    :
                                    (
                                        <Alert severity="warning" 
                                            sx={{
                                                mt:10,
                                                ml:60,
                                                mr:60,
                                            }}
                                        >
                                            You pressed search too quick, try searching again.
                                        </Alert>
                                    )
                                }

                                </ErrorBoundary>
                                </>
                                ) : null
                            }
                        </Grid>
                        </Grid>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Box> 
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
            <div align='center'><Button variant="contained" onClick={signin} sx={{ mt:25 }}>Login with SSO</Button></div>
        </UnauthenticatedTemplate>
        </>
    )
}

