import React, { createContext, useContext, useState } from 'react';

const TextContext = createContext();

// Custom hook, used for accessing text values
export const useText = () => useContext(TextContext);

// Put all text in here and use it everywhere
export const TextProvider = ({ children }) => {
    // State to hold text values
    const [textValues, setTextValues] = useState({
        header: 'D A R T',
        subheader: 'DNS Automated Reconciliation Tool',
        submit_button: 'Validate',
        output_columns: ['Sr. No.', 'Record Type', 'Region', 'Source', 'DNS Server', 'Zone', 'Name', 'TTL', 'Class', 'Type', 'R-Data']
        // Add more text values as needed
    });

    return (
        <TextContext.Provider value={textValues}>
            {children}
        </TextContext.Provider>
    );
};