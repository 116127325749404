import HomeIcon from '@mui/icons-material/Home';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ManageAccountsSharpIcon from '@mui/icons-material/ManageAccountsSharp';
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import AccessibilityNewSharpIcon from '@mui/icons-material/AccessibilityNewSharp';
import SpaceDashboardSharpIcon from '@mui/icons-material/SpaceDashboardSharp';


console.log("Environment : " + process.env.NODE_ENV)

let sideNav = [
    {
        id: 0,
        icon: <HomeIcon/>,
        text: "Home",
        link: "/"
    },
    {
        id: 1,
        icon: <PeopleSharpIcon/>,
        text: "Users",
        link: "/users"
    },
    {
        id: 2,
        icon: <ManageAccountsSharpIcon/>,
        text: "Accounts",
        link: "/accounts"
    },
    {
        id: 3,
        icon: <CrisisAlertIcon/>,
        text: "DART",
        link: "/dart"
    },
    {
        id: 4,
        icon: <ManageSearchIcon/>,
        text: "DNS Report",
        link: "/report"
    },
    {
        id: 5,
        icon: <AccessibilityNewSharpIcon/>,
        text: "RMP Referee",
        link: "/rmp"
    },
    {
        id: 6,
        icon: <SpaceDashboardSharpIcon/>,
        text: "Customer Dashboards",
        link: "/customers"
    }
]

export const navData = sideNav
