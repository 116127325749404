import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';


const maxlimit = 25;
const urlmaxlimit = 45;

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
})); 

const columns = [
  { id: 'srno', label: 'ID', minWidth: 0, align: 'center' },
  { id: 'recordtype', label: 'Record', minWidth: 0, align: 'center' },
  { id: 'region', label: 'Region', minWidth: 0, align: 'left' },
  { id: 'domain', label: 'Domain Name', minWidth: 0, align: 'left' },
  { id: 'aws', label: 'AWS', minWidth: 0, align: 'left' },
  { id: 'gtm', label: 'GTM', minWidth: 0, align: 'left' },
  { id: 'global', label: 'Global', minWidth: 0, align: 'left' },
  { id: 'status', label: 'Matched', minWidth: 0, align: 'center' },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#f1f1f1',
      color: '#000000',
      fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
}));
  
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

function getHighlightedText(text, highlight) {
    if (highlight !== "*") {
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <mark>{part}</mark> : part)}</span>;
    } else {
        return text;
    }
}

export default function SearchTable({ rows, search }) {

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(20);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 665 }}>
            <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead>
                <StyledTableRow>
                {columns.map((column) => (
                    <StyledTableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                    >
                    {column.label}
                    </StyledTableCell>
                ))}
                </StyledTableRow>
            </TableHead>
            <TableBody>
                {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row,index) => {
                    return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            <TableCell align='center'>{(index + 1) + (page * rowsPerPage)}</TableCell>
                            <TableCell align='center'>{row['Record Type']}</TableCell>
                            <TableCell align='left'>{row['Region']}</TableCell>
                            <TableCell align='left'>
                                {((row['Domain']).length > urlmaxlimit) ? (getHighlightedText((row['Domain']).substring(0,urlmaxlimit-3), search)) + '...' : getHighlightedText(row['Domain'], search)}
                            </TableCell>
                            <TableCell align='left'>{(row['AWS']) ? (((row['AWS']).length > maxlimit) ? (((row['AWS']).substring(0,maxlimit-3)) + '...') : row['AWS']) : 'Not Found'}</TableCell>
                            <TableCell align='left'>{(row['GTM']) ? (((row['GTM']).length > maxlimit) ? (((row['GTM']).substring(0,maxlimit-3)) + '...') : row['GTM']) : 'Not Found'}</TableCell>
                            <TableCell align='left'>{(row['Global']) ? (((row['Global']).length > maxlimit) ? (((row['Global']).substring(0,maxlimit-3)) + '...') : row['Global']) : 'Not Found'}</TableCell>
                            <TableCell align='center'><HtmlTooltip title={<React.Fragment>DNS : {row['Domain']}<br />AWS : {row['AWS']}<br />GTM : {row['GTM']}<br />Global : {row['Global']}</React.Fragment>}>{(row['Match']) ? 'Yes' : 'No'}</HtmlTooltip></TableCell>
                        </TableRow>                        
                    );
                })}

            </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        sx = {{ height: '40px' }} />
        </Paper>
    );

}