import React, { useState, useEffect } from 'react';
import Header from "../components/Header"
import Input from '../components/Input';
import { Box, Container, Grid, CircularProgress } from '@mui/material';
import isEmpty from "lodash/isEmpty";
import { setNewSearchParams, enVars } from '../utils/app_logic';
import SubHeader from '../components/SubHeader';
import { recordTypes } from "../utils/recordTypes";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DisplayTable from '../components/DisplayTable';
import axios from 'axios';
import { ErrorBoundary } from 'react-error-boundary';
import Alert from '@mui/material/Alert';


export default function App() {

    const [url, setUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [rows, setRows] = useState([]);
    const [searchParams] = useState(new URLSearchParams(window.location.search));
    const record_types = []
    const [selectedRecords, setSelectedRecords] = useState([])

    const [checkedState, setCheckedState] = useState(
        new Array(recordTypes.length).fill(false)
    );

    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);

        if (updatedCheckedState[0]) { record_types.push('"A"'); } 
        if (updatedCheckedState[1]) { record_types.push('"AAAA"'); } 
        if (updatedCheckedState[2]) { record_types.push('"CNAME"'); } 
        if (updatedCheckedState[3]) { record_types.push('"NS"'); } 
        if (updatedCheckedState[4]) { record_types.push('"TXT"'); } 

        const setData = new Set(record_types);
        const uniqueArray = Array.from(setData);

        setSelectedRecords(uniqueArray)

    };

    useEffect(() => {
        if((searchParams.get("url") && searchParams.get("url") !== null)) {
            setUrl(searchParams.get("url"))
        }
    }, [searchParams]);

    useEffect(() => {
        handleOnChange(0)
    }, []);

    const retrieveData = async () => {

        const rows = [];
        const token = enVars('requester', 'key');
        const api_url = enVars('requester', 'url');

        setLoading(true);
        setButtonEnabled(false);
        setRows([]);
        setNewSearchParams(url, searchParams);

        const payload = `{ "domain": "${url}", "record_types": [ ${selectedRecords} ] }`

        try {

            const response = await axios.post(
                api_url,
                `${payload}`, {
                headers: {
                    'x-api-key':`${token}`
                }
            });

            const result_payload = JSON.parse(response.request.response).responses;

            if (result_payload) {

                result_payload.map((items, index) => ( 
                    rows.push({ 
                        resolves: items.resolves
                    })
                )) 
                
            }

        } catch (error) {
            console.log(error.message);
        }
         
        setRows(rows);
        setLoading(false);
        setButtonEnabled(true);

    };

    return (

        <Box sx={{ flexGrow: 1 }}>
            <Container sx={{ mt: 5 }}>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center" 
                >
                    <Header />
                    <SubHeader />
                    <Input
                        url={url}
                        setUrl={setUrl}
                        retrieveData={retrieveData}
                        buttonEnabled={buttonEnabled}
                    />
                    <FormGroup aria-label="position" row>
                        {recordTypes.map(({ name }, index) => {
                            return (
                                <FormControlLabel key={index} control={
                                    <Checkbox
                                        id={`custom-checkbox-${index}`}
                                        name={name}
                                        value={name}
                                        checked={checkedState[index]}
                                        onChange={() => handleOnChange(index)}
                                        size="small"
                                    /> 
                                } label={name} /> 
                            );
                        })}
                    </FormGroup>
                    
                    {!loading ? (
                        !isEmpty(rows) ? (
                            <ErrorBoundary 
                            fallback={
                                <Alert variant="filled" severity="error" sx={{ mt:5 }}>
                                    Unable to fetch results for the URL provided.
                                </Alert>
                            }
                            onError={() => console.log("Error happened !!")}
                            >
                            <DisplayTable rows={rows}/>
                            </ErrorBoundary>
                        ) : null
                        
                    ) : (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                            }}
                        />
                    )}
                </Grid>
            </Container>
        </Box>

    );
};
