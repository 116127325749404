import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
}));  

function DisplayChain({ data }) {

    const responseItems = data.map((items, index) => (

        <li key={index.toString()}> {index + 1}
            <ul>
                <li>DNS Server - {items.dns_server}</li>
                <li>Zone Name - {items.zone_name}</li>
                <li>Record Sets
                    <ul>
                        {items.record_sets.map((recordItem, recordIndex) => (
                            <>
                                <li>Name - {recordItem.name}</li>
                                <li>TTL - {recordItem.ttl}</li>
                                <li>Class - {recordItem.class}</li>
                                <li>Record Type - {recordItem.type}</li>
                                <li>R Data
                                    <ul>
                                        {recordItem.r_data.map((rdataItem, rdataIndex) => (
                                            <>
                                                <li>{rdataItem}</li>
                                            </>
                                        ))}
                                    </ul>
                                </li>
                            </>
                        ))}
                    </ul>
                </li>
                {(items.error) ? <li>Error - {items.error}</li> : ""}
            </ul>
        </li>
    ));

    return (
        <ul>
            <li>Responses
                <ul>
                    {responseItems}
                </ul>
            </li>
        </ul>
    )

}

function compareIPAddresses(a, b) {
    const numA = Number(
      a.split('.')
        .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
        .reduce((a, v) => ((a += v), a), 0)
    );
    const numB = Number(
      b.split('.')
        .map((num, idx) => num * Math.pow(2, (3 - idx) * 8))
        .reduce((a, v) => ((a += v), a), 0)
    );
    return numA - numB;
}

function DisplayCellData({ data, source, aws, gtm, global, cell_of }) {

    let status = 'warning';
    let sorted_source = '';
    let ip_addresses = '';

    let sorted_aws = '';
    let sorted_gtm = '';
    let sorted_global = '';

    aws = !aws ? source : aws;
    gtm = !gtm ? source : gtm;
    global = !global ? source : global;

    if (/Error/.test(source)) {
        ip_addresses = source;
        status = 'error';
    } else {
        sorted_source = source.sort(compareIPAddresses);
        ip_addresses = sorted_source.map((ip) => <div>* {ip}</div>);

        if (!/Error/.test(aws)) {
            sorted_aws = aws.sort(compareIPAddresses);
        }
        if (!/Error/.test(gtm)) {
            sorted_gtm = gtm.sort(compareIPAddresses);
        }
        if (!/Error/.test(global)) {
            sorted_global = global.sort(compareIPAddresses);
        }

        if ((JSON.stringify(sorted_aws) === JSON.stringify(sorted_gtm)) && (JSON.stringify(sorted_aws) === JSON.stringify(sorted_global)) && (JSON.stringify(sorted_gtm) === JSON.stringify(sorted_global))) {
            status = 'success';
        }

        if (((JSON.stringify(sorted_aws) === JSON.stringify(sorted_gtm))) && ((cell_of === 'aws') || (cell_of === 'gtm'))) {
            status = 'success';
        }

        if (((JSON.stringify(sorted_aws) === JSON.stringify(sorted_global))) && ((cell_of === 'aws') || (cell_of === 'global'))) {
            status = 'success';
        }

        if (((JSON.stringify(sorted_global) === JSON.stringify(sorted_gtm))) && ((cell_of === 'global') || (cell_of === 'gtm'))) {
            status = 'success';
        }

    }

    return (
        <Stack sx={{ width: '100%' }} spacing={{ xs: 1, sm: 2 }}>
            <HtmlTooltip
            title={
                <React.Fragment>
                    <DisplayChain data={data} />
                </React.Fragment>
            } 
            >
                <Alert severity={status}>
                    {ip_addresses}
                </Alert>
            </HtmlTooltip>                        
        </Stack>
    )

}

export default function DenseTable({ rows }) {

    return (

        <TableContainer component={Paper} sx={{ mt: 2, mb: 10 }}>
            <Table sx={{  }} size="small" aria-label="a dense table">
                <TableHead sx={{ maxWidth: 1200 }}>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 900 }} align="center" style={{whiteSpace: "nowrap"}}>Region</TableCell>  
                        <TableCell sx={{ fontWeight: 900 }} align="center" style={{whiteSpace: "nowrap"}}>Record Type</TableCell> 
                        <TableCell sx={{ fontWeight: 900 }} align="center" style={{whiteSpace: "nowrap"}}>R Data (AWS)</TableCell>
                        <TableCell sx={{ fontWeight: 900 }} align="center" style={{whiteSpace: "nowrap"}}>R Data (GTM)</TableCell>
                        <TableCell sx={{ fontWeight: 900 }} align="center" style={{whiteSpace: "nowrap"}}>R Data (Global)</TableCell>
                    </TableRow>
                </TableHead> 
                <TableBody>

                {rows.map((row, index) => (
                    row.resolves.map((items) => (
                        <TableRow>
                            <TableCell align="center" style={{whiteSpace: "nowrap"}}>{items[0].response.region}</TableCell>
                            <TableCell align="center" style={{whiteSpace: "nowrap"}}>{items[0].request.record_type}</TableCell>
                            <TableCell align="left" valign="top"><DisplayCellData data={items[0].response.chain} source={(items[0].response.chain.at(-1).record_sets.at(-1)) ? items[0].response.chain.at(-1).record_sets.at(-1)['r_data'] : "Error : " + items[0].response.chain.at(-1)['error']} gtm={(items[1].response.chain.at(-1).record_sets.at(-1)) ? items[1].response.chain.at(-1).record_sets.at(-1)['r_data'] : "Error : " + items[1].response.chain.at(-1)['error']} global={(items[2].response.chain.at(-1).record_sets.at(-1)) ? items[2].response.chain.at(-1).record_sets.at(-1)['r_data'] : "Error : " + items[2].response.chain.at(-1)['error']} cell_of={'aws'} /></TableCell>
                            <TableCell align="left" valign="top"><DisplayCellData data={items[1].response.chain} aws={(items[0].response.chain.at(-1).record_sets.at(-1)) ? items[0].response.chain.at(-1).record_sets.at(-1)['r_data'] : "Error : " + items[0].response.chain.at(-1)['error']} source={(items[1].response.chain.at(-1).record_sets.at(-1)) ? items[1].response.chain.at(-1).record_sets.at(-1)['r_data'] : "Error : " + items[1].response.chain.at(-1)['error']} global={(items[2].response.chain.at(-1).record_sets.at(-1)) ? items[2].response.chain.at(-1).record_sets.at(-1)['r_data'] : "Error : " + items[2].response.chain.at(-1)['error']} cell_of={'gtm'} /></TableCell>
                            <TableCell align="left" valign="top"><DisplayCellData data={items[2].response.chain} aws={(items[0].response.chain.at(-1).record_sets.at(-1)) ? items[0].response.chain.at(-1).record_sets.at(-1)['r_data'] : "Error : " + items[0].response.chain.at(-1)['error']} gtm={(items[1].response.chain.at(-1).record_sets.at(-1)) ? items[1].response.chain.at(-1).record_sets.at(-1)['r_data'] : "Error : " + items[1].response.chain.at(-1)['error']} source={(items[2].response.chain.at(-1).record_sets.at(-1)) ? items[2].response.chain.at(-1).record_sets.at(-1)['r_data'] : "Error : " + items[2].response.chain.at(-1)['error']} cell_of={'global'} /></TableCell>
                        </TableRow>  
                    ))          
                ))}

                </TableBody>
            </Table> 
        </TableContainer>

    );
}
