import React, { useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { ThemeContext } from "../providers/ThemeProvider";
import { useText } from "../providers/TextProvider";

export default function Header() {
    const { theme } = useContext(ThemeContext);
    const textValues = useText();
    return (
        <Grid item>
            <Typography
                variant="h2"
                align="center"
                sx={{ fontWeight: "bold", color: theme.palette.primary.main }}
            >
                {textValues.header}
            </Typography>
        </Grid>
    );
}