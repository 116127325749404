import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LaunchSharpIcon from '@mui/icons-material/LaunchSharp';


function createData(customer_name, dd_admin, dd_external) {
    return { customer_name, dd_admin, dd_external };
}

const rows = [
    
    createData('OTE', 'https://comcastcsp.datadoghq.com/dashboard/56m-5ad-edf/ote-customer-dashboard---hd', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-f10fb554bda2de26ce976191c2b803e2'),
    createData('DT Poland', 'https://comcastcsp.datadoghq.com/dashboard/sst-yqh-rug/dt-poland---customer-dashboard-new', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-17eec76ed8dddea4d94377e55acb7cc6'),
    createData('DT Magenta (Austria)', 'https://comcastcsp.datadoghq.com/dashboard/h5i-xdq-yae/dt-austria---customer-dashboard', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-afdb749a0bae989ba0b2b636ff3975f5'),
    createData('DT Croatia', 'https://comcastcsp.datadoghq.com/dashboard/35i-wnr-vh3/dt-croatia---customer-dashboard', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-a29f278d2c981b89926bf97aefd55e56'),
    createData('WRC', 'https://comcastcsp.datadoghq.com/dashboard/str-dhr-nse/wrc-dashboard', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-cf25e84a66f5edf509e7affe18e85ae2'),
    createData('BT', 'https://comcastcsp.datadoghq.com/dashboard/i4i-tq7-eh8/bt-customer-dashboard?from_ts=1677594579500&to_ts=1677594879500&live=true', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-c60dabae0b8d851b298a4b6abbcdc4fc'),
    createData('OSN', 'https://comcastcsp.datadoghq.com/dashboard/jcb-4ab-ux5/osn---customer-dashboard', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-821ebe4aba6f7bd39a9c8ba99a54830c'),
    createData('Altibox', 'https://comcastcsp.datadoghq.com/dashboard/k8z-p8g-m4n', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-2d8d3cf33ed664ad1cb9e615752b9f2b'),
    createData('DT Montenegro', 'https://comcastcsp.datadoghq.com/dashboard/hhb-44k-v35/dt-montenegro---customer-dashboard-new', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-9c073449c0cb27395126bac6e9754796'),
    createData('DT Germany', 'https://comcastcsp.datadoghq.com/dashboard/3h6-tvn-q3d/dt-germany---customer-dashboard', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-6e4c692d8410864f0411a3f48bcc2f96'),
    createData('Intigral', 'https://comcastcsp.datadoghq.com/dashboard/qre-96w-9qs', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-53e5af0097ca071c825a17cd34d4b31a'),
    createData('Viaplay', 'https://comcastcsp.datadoghq.com/dashboard/bmp-zuz-y98', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-d0ee630163ddb6f5f9ecaab6654a0be7'),
    createData('Mediaset', 'https://comcastcsp.datadoghq.com/dashboard/mu4-nry-cme/mediaset-customer-dashboard', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-2569db0d3ff852237e3bc699a11849f8'),
    createData('Hayu', 'https://comcastcsp.datadoghq.com/dashboard/d47-ntw-uvr', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-24fb075528fa4bcd92e68f00c594d883'),
    createData('RTE', 'https://comcastcsp.datadoghq.com/dashboard/43w-7d8-kxj', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-591dc75549f67c14ee7f9cf690cb1292'),
    createData('OCS', 'https://comcastcsp.datadoghq.com/dashboard/nuu-khr-tdb/ocs-customer-dashboard', 'https://p.datadoghq.com/sb/72eafea2-ac4e-11eb-a22c-da7ad0900002-9c073449c0cb27395126bac6e9754796'),

];


export default function DenseTable() {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><Typography variant="h6" gutterBottom>Customer</Typography></TableCell>
            <TableCell align="center"><Typography variant="h6" gutterBottom>DD Admin URL</Typography></TableCell>
            <TableCell align="center"><Typography variant="h6" gutterBottom>DD External URL</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.customer_name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.customer_name}
              </TableCell>
              <TableCell align="center"><a href={row.dd_admin}><LaunchSharpIcon fontSize='small' /></a></TableCell>
              <TableCell align="center"><a href={row.dd_external}><LaunchSharpIcon fontSize='small' /></a></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
