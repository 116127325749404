import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import Report from './pages/Report';
import Home from './pages/Home';
import Dart from './pages/Dart';
import Accounts from './pages/Accounts';
import Users from './pages/Users';
import Rmp from './pages/Rmp';
import Customers from './pages/Customers';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '../src/providers/ThemeProvider';
import { TextProvider } from '../src/providers/TextProvider';
import CssBaseline from "@mui/material/CssBaseline";
import { createBrowserRouter, RouterProvider, BrowserRouter } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './utils/authConfig';


/**
* MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
* For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/
const msalInstance = new PublicClientApplication(msalConfig);
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/users",
    element: <Users />,
  },
  {
    path: "/accounts",
    element: <Accounts />,
  },
  {
    path: "/dart",
    element: <Dart />,
  },
  {
    path: "/report",
    element: <Report />,
  },
  {
    path: "/rmp",
    element: <Rmp />,
  },
  {
    path: "/customers",
    element: <Customers />,
  },
]);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <CssBaseline>
        <TextProvider>
            <MsalProvider instance={msalInstance}>
              <RouterProvider router={router} />
            </MsalProvider>
        </TextProvider>
      </CssBaseline>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
