export const recordTypes = [
    {
        name: "A"
    },
    {
        name: "AAAA"
    },
    {
        name: "CNAME"
    },
    {
        name: "NS"
    },
    {
        name: "TXT"
    }
];