import React from 'react';
import { Grid, Typography } from "@mui/material";
import { useText } from "../providers/TextProvider"

export default function SubHeader() {
    const textValues = useText()
    return (
        <Grid item>
            <Typography
                variant="inherit"
                align="center"
                sx={{ fontWeight: "bold" }}
            >
                {textValues.subheader}
            </Typography>
        </Grid>
    );
}