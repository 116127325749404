import React, { useState } from 'react';
import { ThemeProvider as MultiThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        custom_background: "#181818",
    },
});

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        custom_background: "#181818",
    },
});

export const ThemeContext = React.createContext({
    theme: darkTheme,
    toggleTheme: () => {},
});

export const ThemeProvider = ({ children }) => {
    const [theme, setTheme] = useState(lightTheme);

    const toggleTheme = () => {
        setTheme(theme === darkTheme ? lightTheme : darkTheme);
    };

    return (
        <MultiThemeProvider theme={theme}>
            <ThemeContext.Provider value={{ theme, toggleTheme }}>
                {children}
            </ThemeContext.Provider>
        </MultiThemeProvider>
    );
};