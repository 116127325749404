import React, { useContext } from 'react';
import { ThemeContext } from '../providers/ThemeProvider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

export default function ThemeSwitcher() {
    const { theme, toggleTheme } = useContext(ThemeContext);
    return (
        <FormGroup
            sx={{
                position: "absolute",
                top: 16,
                right: 16,
            }}
        >
            <FormControlLabel onClick={() => toggleTheme()} control={<Switch />} label={theme.palette.mode} />
        </FormGroup>
    )
}