import * as React from 'react';
import Box from '@mui/material/Box';
import '../App.css';
import ButtonAppBar from '../providers/TopNavigation'
import Sidenav from '../components/Sidenav';
import { useIsAuthenticated } from "@azure/msal-react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider, useMsal } from '@azure/msal-react';
import { loginRequest } from "../utils/authConfig";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LookupTool from '../components/LookupTool';
import Grid from '@mui/material/Grid';
import Login from '../components/Login';


export default function Lookup() {

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const token = sessionStorage.getItem('token');

    const logout = (event) => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    }

    const signin = (event) => {
        instance.loginRedirect(loginRequest).catch(
            e => {console.log(e);
        });
    }
    
    return (
        <>
        <AuthenticatedTemplate>
        <Box sx={{ flexGrow: 1 }}>
            <ButtonAppBar />
            <table width="100%" cellSpacing="0" cellPadding="0">
                <tbody>
                    <tr>
                        <td width="5%" valign='top'><Sidenav/></td>
                        <td valign='top' align='center'>
                            {(isAuthenticated) ? (<div align='right'><Typography variant="body1" sx={{ mr: 2, mt:0 }}>Hello <b>{ accounts[0].name }</b> <Button variant="text" color='error' onClick={logout}>Logout</Button></Typography></div>) : null }
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sx={{m:5}}>
                                        {(token) ? <LookupTool /> : <Login referrer={'/user'} />}
                                    </Grid>
                                </Grid>
                            </Box>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Box>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
            <div align='center'><Button variant="contained" onClick={signin} sx={{ mt:25 }}>Login with SSO</Button></div>
        </UnauthenticatedTemplate>
        </>
    )
}

