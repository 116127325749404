import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Box, Container, CircularProgress } from '@mui/material';
import isEmpty from "lodash/isEmpty";
import Alert from '@mui/material/Alert';
import { ErrorBoundary } from 'react-error-boundary';
import { getAccountsData, filterAccounts, getAccountDetails } from '../utils/dataService';
import Autocomplete from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import AccountsView from './AccountsView';
import { isNull } from 'lodash';


export default function AccountsLookup() {

    const [helper, setHelper] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [noRecords, setNoRecords] = useState(false);
    const [parent, setParent] = useState(false);
    const [status, setStatus] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [value, setValue] = useState(null);
    const [searchStatus, setSearchStatus] = useState(false);
    const [loadingText, setLoadingText] =  useState('Type account name ...');

    const GroupHeader = styled('div')(({ theme }) => ({
        position: 'sticky',
        top: '-8px',
        padding: '4px 10px',
        color: theme.palette.primary.main,
        backgroundColor:
          theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
    }));

    const GroupItems = styled('ul')({
        padding: 0,
    });

    const options = accounts.map((option) => {
        const firstLetter = option.account_name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
    });

    const defaultProps = {
        options: accounts,
        getOptionLabel: (option) => option.account_id,
    };

    const handleChangeParent = (event) => {
        setParent(event.target.checked);
    };

    const handleChangeStatus = (event) => {
        setStatus(event.target.checked);
    };

    const retrieveData = async () => {
        if (value) {
            setLoading(true);
            const account_details = await getAccountDetails(value['account_id']);
            setData(account_details);
            setLoading(false);
        }
    }

    useEffect(() => {
        
        var nowDate = new Date(); 
        var date = nowDate.getDate()+'/'+(nowDate.getMonth()+1)+'/'+nowDate.getFullYear(); 
        const dataStoreDate = localStorage.getItem('datastore_date');
        
        if (date !== dataStoreDate) {
            const fetchData = async () => {
                setSearchStatus(true);
                setLoadingText('Loading ... pls wait');
                const all_accounts = await getAccountsData();
                localStorage.setItem('datastore_date', date);
                const searchCriteria = filterAccounts(parent, status);
                setAccounts(searchCriteria);
                setSearchStatus(false);
                setLoadingText('Type account name ...');
            }
            fetchData().catch(console.error);
        }

        const searchCriteria = filterAccounts(parent, status);
        setAccounts(searchCriteria);
        
    }, [parent, status, value])

    return (
        
        <Box sx={{ flexGrow: 1 }}>
        <Container sx={{ mt: 5 }}>

        <ErrorBoundary 
            fallback={
                <Alert variant="filled" severity="error" sx={{ ml:45, mr:45 }}>
                    Unable to load autocomplete data.
                </Alert>
            }
            onError={() => console.log("Error occured !!")}
        >

        <Grid
            container
            item
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            xs={12}
            sm={6}
            lg={10}
            sx={{ my: 4 }}
        >
            <Grid item sm={6} md={6}>
                <Autocomplete
                    {...defaultProps}
                    id="account_lookup"
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.account_name}
                    renderInput={(params) => 
                        <TextField {...params}
                            fullWidth
                            required
                            id="context"
                            label={loadingText}
                            variant="standard"
                            helperText={helper}
                            disabled={searchStatus}
                        />                    
                    }
                    renderGroup={(params) => (
                        <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                        </li>
                    )}
                />
                
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    onClick={() => retrieveData()}
                    size="large"
                >
                    Search 
                </Button>
            </Grid>
        </Grid>

        <Grid item sm={6} md={6}>
            <FormGroup>
                <FormControlLabel 
                    control={
                        <Checkbox 
                        checked={parent}
                        onChange={handleChangeParent}
                        inputProps={{ 'aria-label': 'controlled' }} 
                        />} 
                    label="Search parent accounts only" 
                />
                <FormControlLabel 
                    control={
                        <Checkbox 
                        checked={status}
                        onChange={handleChangeStatus}
                        inputProps={{ 'aria-label': 'controlled' }} 
                        />} 
                    label="Exclude disabled accounts" 
                />
            </FormGroup>
        </Grid>

        </ErrorBoundary>

        <Grid item sx={{ mt: 10}} align='left'>
            {!loading ? (
            !isEmpty(data) ? (
                <ErrorBoundary 
                fallback={
                    <Alert variant="filled" severity="error" sx={{ ml:45, mr:45 }}>
                        Unable to fetch data from data service.
                    </Alert>
                }
                onError={() => console.log("Error occured !!")}
                >
                {(value) ? <AccountsView rows={data} account_id={value.account_id} /> : null }
                
                </ErrorBoundary>
            ) :  noRecords ? <Alert variant="filled" severity="error" sx={{ ml:45, mr:45 }}>No records found for {value}</Alert> : null
                                        
            ) : (
                <CircularProgress
                    size={24}
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                    }}
                />
            )}
        </Grid>
        </Container>
        </Box>
    );

}
